import React from "react";
import Navbar from "components/Navbar/navbar";
import Footer from "components/Footer/footer";
import ProjectDetails2Header from "components/Project-details2-header/project-details2-header";
import ProjectIntroduction from "components/Project-introduction/project-introduction";
import ProjectDescription from "components/Project-description/project-description";
import DarkTheme from "layouts/Dark";
import {graphql} from "gatsby";

const ProjectPage = ({
    data: {
      project
    }
}) => {
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  console.log(project);
  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <Navbar nr={navbarRef} lr={logoRef} />
      <div className="wrapper">
        <ProjectDetails2Header projectHeaderData={project} />
        <ProjectIntroduction projectIntroductionData={project} />
        <ProjectDescription projectDescriptionData={project.description} />
        <Footer />
      </div>
    </DarkTheme>
  );
};

export const Head = ({
  data: {
    project
  }
}) => {
  return (
    <>
       <title>Recent Project {project.title}</title>
       <meta name="description" content={project.summary} />
    </>
  )
}

export const query = graphql`
  query($id: String) {
    project(id: {eq: $id}) {
      id
      slug
      budget
      client
      clientURLLink
      projectHeaderImage
      summary
      title
      description
      duration
      services
      technologies
    }
  }
`;

export default ProjectPage;
