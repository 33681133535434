import React from "react";
import { Link } from "gatsby";

const ProjectDetails2Header = ({ projectHeaderData }) => {
  return (
    <section
      className="page-header proj-det bg-img parallaxie valign"
      style={{ backgroundImage: `url(${projectHeaderData.projectHeaderImage})` }}
      data-overlay-dark="9"
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-9">
            <div className="cont">
              <h2>{projectHeaderData.title}</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="item mt-30">
              <h6>Client</h6>
              <p>
                <a rel="noopener noreferrer nofollow" target="_blank" href={projectHeaderData.clientURLLink}>
                  {projectHeaderData.client}
                </a>
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="item mt-30">
              <h6>Duration</h6>
              <p>{projectHeaderData.duration}</p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="item mt-30">
              <h6>Services</h6>
              <p>
                {projectHeaderData.services.map((cat, index) => (
                    <span>
                      {cat}
                      {projectHeaderData.services.length != index + 1 ? ", " : ""}
                    </span>
                ))}
              </p>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="item mt-30">
              <h6>Technologies</h6>
              <p>
                {projectHeaderData.technologies.map((tech, index) => (
                    <span>
                      {tech}
                      {projectHeaderData.technologies.length != index + 1 ? ", " : ""}
                    </span>
                ))}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetails2Header;
